import { FaqEntryType } from "@src/components/schemas/FaqSchema/FaqSchema";
import { getFaqSchemaGql } from "@src/services/contentful/faqSchema/faqSchema";
import { QueryClient } from "@tanstack/react-query";
import { GetStaticProps } from "next";
import { getPage } from "src/lib/api";
import getFooterSection from "src/services/contentful/footer/getFooterMenu";
import { getHeaderMenuGraphQL } from "src/services/contentful/mainHeader/getMainHeader";
import { getSiteSettingsGql } from "src/services/contentful/settings/settings";

export type PageProps = {
  settings: any;
  page: any;
  footerMenu: any;
  consentVisible: boolean;
  preview: boolean;
  header: any;
  faqEntries: FaqEntryType[] | undefined;
};
export { default } from "src/pages/page";

export const getStaticProps: GetStaticProps = async (data) => {
  // console.log("getStaticProps", data);
  const { params } = data;
  const queryClient = new QueryClient();
  const preview = data?.preview || false;


  //console.log("params?.slug: ", params?.slug);

  let page = await getPage({
    slug: "home",
    preview: data?.preview || false,
    pageContentType: "pageGenericPage",
  });


  const faqEntries = await getFaqSchemaGql({
    queryClient,
    preview,
    slug: params?.slug?.toString().toLowerCase(),
  });

  // console.log(
  //   "getStaticProps:data:",
  //   params?.slug,
  //   page
  //   // data.fields.seo
  // );
  let footerMenu = await getFooterSection({
    query: {
      limit: 1,
      include: 5,
    },
    preview: data?.preview || false,
  });


  let settings = await getSiteSettingsGql({
    queryClient,
    preview: data?.preview || false,
  });

  //console.log("footerMenu: ", JSON.stringify(footerMenu));

  // let header = await getHeaderMenu({
  //   query: {
  //     limit: 1,
  //   },
  //   preview: data?.preview || false,
  // });

  let headergql = await getHeaderMenuGraphQL({
    preview: data?.preview || false,
  });
  // console.log("headergql", JSON.stringify(headergql, null, 2));

  //console.log("page: ", removeKeys(page, ["sys", "metadata"]));

  const props: PageProps ={
    settings,
    page,
    footerMenu,
    consentVisible: true,
    preview: data?.preview || false,
    header: headergql,
    faqEntries,
  };

  return {
    props,
  };
};
